import $ from "jquery"

export function updateUrlWithParams(url, params, removePrefixes) {
    var anchor, query, pos;

    if (removePrefixes === undefined) removePrefixes = [];

    // split anchor from url
    pos = url.indexOf('#');
    if (pos > 0) {
        anchor = url.substring(pos + 1);
        url = url.substring(0, pos);
    } else {
        anchor = "";
    }

    // split query from url
    pos = url.indexOf('?');
    if (pos > 0) {
        query = url.substring(pos + 1);
        url = url.substring(0, pos);
    } else {
        query = "";
    }

    // parse query into an object
    var parsed_query = {};
    query.replace(/&*([^=&]+)=([^=&]*)/gi, function(str,key,value) {
        key = decodeURIComponent(key.replace(/\+/g, ' '));
        value = decodeURIComponent(value.replace(/\+/g, ' '));

        var skip = false;
        removePrefixes.forEach(function (prefix) {
            if (key.substring(0, prefix.length) == prefix) skip = true;
        });

        if (!skip) {
            if (!parsed_query.hasOwnProperty(key)) parsed_query[key] = [];
            parsed_query[key].push(value);
        }
    });

    // aggregate params by name
    var parsed_params = {};
    $.each(params, function (i, pair) {
        if (!parsed_params.hasOwnProperty(pair[0])) parsed_params[pair[0]] = [];
        if (pair[1] !== null && pair[1] !== "") parsed_params[pair[0]].push(pair[1]);
    });

    // update query with parsed_params
    $.each(parsed_params, function (k, v) {
        parsed_query[k] = v;
    });

    // create new URL
    var sep = '?';
    $.each(parsed_query, function (k, values) {
        $.each(values, function (i, val) {
            if (val !== null) {
                url += sep + encodeURIComponent(k) + '=' + encodeURIComponent(val);
                sep = '&'
            }
        });
    });

    if (anchor !== '') {
        url += '#' + anchor
    }

    return url;
}

$(document).ready(function () {
    // timeout used so URL is not updated too often on typing
    var pushStateTimeout = null;

    // url that represents the last known state
    var currentStateUrl = location.href;

    // currently running AJAX
    var currentXhr = null;

    $('.listing').each(function () {
        var $listing = $(this);
        var $filters = $listing.find('.filters');
        var $content = $listing.find('.listing-content');
        var $pages = $listing.find('.pages');
        var name = $listing.data('name');

        // factory for success function for AJAX updates
        var updateFn = function (delayedPush, withFilters, params, removePrefixes) {
            return function (data) {
                // update html
                $content.html(data.content);
                $pages.html(data.pages);

                $(data.filters).find('.filter').each(function () {
                    if (withFilters || $(this).is('.filter-multicheck')) {
                        $filters.find("#" + this.id).replaceWith(this);
                    }
                });
                $filters.find('.dropdown-check').dropdownCheck();

                // update current state according to params sent from Rails
                if (!params) params = data.params;
                currentStateUrl = updateUrlWithParams(location.href, params, removePrefixes);

                // push state to history
                if (pushStateTimeout) clearTimeout(pushStateTimeout);
                if (delayedPush) {
                    pushStateTimeout = setTimeout(function () {history.pushState(location.href, "listing", currentStateUrl)}, 300);
                } else {
                    history.pushState(location.href, "listing", currentStateUrl);
                }

                $listing.trigger('update.listpress', currentStateUrl);
                $listing.removeClass('loading');
            }
        };

        // makes AJAX request and handles the response
        var doRequest = function (url, delayedPush, withFilters, forceParams, removePrefixes) {
            if (currentXhr) currentXhr.abort();

            $listing.addClass('loading');

            let post_url = $listing.data('postUrl');
            let request_url = url, data = null, method = 'GET';

            if (post_url) {
                request_url = post_url.split('?')[0] + '?' + url.split('?')[1];
                data = $listing.data('postData');
                method = 'POST'
            }

            currentXhr = $.ajax(request_url, {
                method: method,
                data: data,
                dataType: 'json',
                success: updateFn(delayedPush, withFilters, forceParams, removePrefixes),
                error: function (xhr, status, error) {
                    if (status != "abort") {
                        console.log("AJAX failed:", xhr, status, error);
                        // window.location = url;
                    }
                }
            });
        };

        // refresh with on demand
        $listing.on('refresh.listpress', function () {
            doRequest(updateUrlWithParams(currentStateUrl, [["listing", name]]));
        })

        // handle paging links
        $pages.on('click.listpress', 'a[href]', function () {
            doRequest(updateUrlWithParams(currentStateUrl, [["listing", name], [name + "[page]", $(this).data('page')]]));
            return false;
        });

        // handle sorting links
        $content.on('click.listpress', 'a.sort', function () {
            doRequest(updateUrlWithParams(currentStateUrl, [["listing", name], [name + "[sort]", $(this).data('sort')]]));
            return false;
        });

        // refresh - refresh a single item
        // edit - load editing version of the item (with in-line editing form)
        $content.on('refresh.listpress edit.listpress', '.listing-item', function (e, edit_data) {
            var id = $(this).data('id');

            var params = [["listing", name], ["listing_item_id", id]];
            if (e.type == "edit") params.push(["listing_edit", "1"]);

            var url = updateUrlWithParams(currentStateUrl, params);

            $.ajax(url, {
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    // replace item with an item loaded with ajax
                    var $item = $(data.content).find('.listing-item').eq(0);
                    $content.find('.listing-item[data-id=' + id + ']').replaceWith($item);

                    if (e.type == "edit") {
                        // activate form field in the same cell as has been clicked - correct index must be sent in edit_data
                        setTimeout(function () {
                            var $first_input = $item.find('.editing').eq(edit_data).find('input, select, textarea').filter(':visible').first();
                            $first_input.focus();
                            if ($first_input.is('[type=text]')) {
                                $first_input[0].select();
                            }
                        }, 0);
                    }
                    $listing.trigger('update.listpress', currentStateUrl);
                },
                error: function (xhr, status, error) {
                    if (status != "abort") {
                        console.log("AJAX failed:", xhr, status, error);
                        window.location = currentStateUrl;
                    }
                }
            });
        });

        // handle filters
        var filterTimeout = null;

        $filters.on('submit.listpress', 'form', function () {return false;}); // prevent manual form submit

        var submitFilters = function () {
            // collect form data as params
            var data = [];

            var emptyFilters = true;
            $.each($filters.find('form').serializeArray(), function (i, o) {
                if (o.name.substring(0, name.length + 8) == name + '[filter]') {
                    data.push([o.name, o.value]);
                    if (o.value != null && o.value != "") emptyFilters = false;
                }
            });
            if (emptyFilters) data.push([name + '[filter][_]', 1]);

            data.push(['listing', name]);
            data.push([name + '[page]', 1]);
            data.push([name + '[reset]', null]);

            // send
            doRequest(updateUrlWithParams(currentStateUrl, data), true, false, data);
        };

        var resetFilters = function () {
            // collect form data as params
            var data = [];
            $.each($filters.find('form').serializeArray(), function (i, o) {
                if (o.name.substring(0, name.length + 8) == name + '[filter]') {
                    data.push([o.name, null])
                }
            });
            data.push(['listing', name]);
            data.push([name + '[page]', 1]);
            data.push([name + '[reset]', 1]);

            var removePrefixes = [name + '[filter]']

            // send
            doRequest(updateUrlWithParams(currentStateUrl, data, removePrefixes), true, true, data, removePrefixes);

            return false;
        };

        $filters.on('input.listpress', 'input[type=text],textarea', function () {
            if (filterTimeout) clearTimeout(filterTimeout);
            if (currentXhr) currentXhr.abort();

            filterTimeout = setTimeout(submitFilters, 500);
        });
        $filters.on('change.dropdownCheck', '.dropdown-check', function () {
            if (filterTimeout) clearTimeout(filterTimeout);
            if (currentXhr) currentXhr.abort();

            filterTimeout = setTimeout(submitFilters, 500);
        });
        $filters.on('change.listpress', 'select', submitFilters);

        $filters.on('click', '.filter-reset', resetFilters);

        // load edit form when .editable is clicked
        $content.on('click.listpress', '.editable', function () {
            var $item = $(this).closest('.listing-item');
            var cell_index = $item.find('.editable').index($(this));
            $item.trigger('edit.listpress', cell_index);
        });

        // close form when cancel button is clicked
        $content.on('click.listpress', '.editing-actions a.btn.cancel', function () {
            $(this).closest('.listing-item').trigger('refresh.listpress');
            return false;
        });

        // submit form on enter, close it on escape
        $content.on("keydown.listpress", ".editing input,.editing select,.editing textarea", function (e) {
            if (e.which == 13 && !$(this).is('textarea')) { // Enter
                $(this.form).submit();
                return false;
            } else if (e.which == 27) { // ESC
                $(this).closest('.listing-item').trigger('refresh.listpress');
                return false;
            }
        });

        // handle form submit
        $content.on("submit.listpress", ".editing-actions form", function () {
            var $form = $(this);
            var actionUrl = this.action; // get absolute URL
            var xhr = new XMLHttpRequest(); // we must pass this one to get access to its responseURL

            $.ajax({
                type: "POST",
                url: actionUrl,
                data: $form.serialize(),
                xhr: function () {
                    return xhr;
                },
                success: function() {
                    // we expect redirect on successful submit
                    if (xhr.responseURL == actionUrl) { // not redirected = fail - submit the form without ajax so user can see what's wrong
                        $form.trigger("submit.not-listpress");
                    } else { // redirected = success - refresh the item
                        $form.closest('.listing-item').trigger('refresh.listpress');
                    }
                }
            });

            return false;
        });

    });

    // reload page when back button is pressed
    $(window).on('popstate.listpress', function () {
        location.reload();
    });
});
