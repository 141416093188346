// Used to fade (disable inputs + css fade) the whole container on which this is used.
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "checkbox" ]

    connect() {
        this.element.querySelectorAll('input:not([type="checkbox"]), select').forEach((input)=>{
            input.dataset.wasDisabledBeforeFade = input.disabled
        })
        this.change()
    }

    change() {
        if (this.checkboxTarget.checked) {
            this.darken()
        } else {
            this.activate()
        }
    }

    darken () {
        this.element.classList.add("faded")
        this.element.querySelectorAll('input:not([type="checkbox"]), select').forEach((input)=>{
            const wasDisabledBeforeFade = input.disabled
            input.setAttribute('disabled', 'true')
            input.dataset.wasDisabledBeforeFade = wasDisabledBeforeFade
        })
    }

    activate() {
        this.element.classList.remove("faded")
        this.element.querySelectorAll('input:not([data-was-disabled-before-fade=true]), select:not([data-was-disabled-before-fade=true])').forEach((input)=>{
            input.removeAttribute('disabled')
        })
    }
}
