import {Controller} from "stimulus"

export default class extends Controller {
    static targets = [
        "slotsMainInput", "startMainInput", "stopMainInput", "fixateMainInput", "autoGroupMainInput", "groupMainInput", "discountMainInput", "destroyMainInput",
        "slotsShowingInput", "startShowingInput", "stopShowingInput", "fixateShowingInput", "spotPriceMainInput", "spotPriceShowingInput", "autoGroupShowingInput", "groupShowingInput", "discountShowingInput", "destroyShowingInput"
    ]

    connect() {
        console.log(`showings mass change with slots input ${this.slotsMainInputTarget}`)
    }

    rewriteSlots() {
        const value = this.slotsMainInputTarget.value
        console.log(`updating slots with value: ${value}`)
        this.slotsShowingInputTargets.forEach(input => {input.value = value })
    }

    rewriteStart() {
        const value = this.startMainInputTarget.value
        console.log(`updating start with value: ${value}`)
        this.startShowingInputTargets.forEach(input => {input.value = value })
    }

    rewriteStop() {
        const value = this.stopMainInputTarget.value
        console.log(`updating stop with value: ${value}`)
        this.stopShowingInputTargets.forEach(input => {input.value = value })
    }

    rewriteFixate() {
        const checked = this.fixateMainInputTarget.checked
        console.log(`updating fixate with checked: ${checked}`)
        this.fixateShowingInputTargets.forEach(input => {input.checked = checked})
    }

    rewriteSpotPrice() {
        const value = this.spotPriceMainInputTarget.value
        console.log(`updating spot price with value: ${value}`)
        this.spotPriceShowingInputTargets.forEach(input => {input.value = value})
        $(".spot_price_input").change()
    }

    rewriteAutoGroup() {
        const checked = this.autoGroupMainInputTarget.checked
        console.log(`updating group with checked: ${checked}`)

        // we need to manually fire the change event, because another handling
        // is being done by the screen-group-select controller (disabling and
        // enabling the group select)
        const event = new Event('change');

        this.autoGroupShowingInputTargets.forEach(input => {
            input.checked = checked
            input.dispatchEvent(event)
        })
    }

    rewriteGroup() {
        const selectedIndex = this.groupMainInputTarget.selectedIndex
        console.log(`updating stop with index: ${selectedIndex}`)
        this.groupShowingInputTargets.forEach(input => {input.selectedIndex = selectedIndex })
    }

    rewriteDiscount() {
        const value = this.discountMainInputTarget.value
        console.log(`updating discount with value: ${value}`)
        this.discountShowingInputTargets.forEach(input => {input.value = value })
    }

    rewriteDestroy() {
        const checked = this.destroyMainInputTarget.checked
        console.log(`updating destroy with checked: ${checked}`)

        // we need to manually fire the click event, because another handling
        // is being done by the fading controller (fading the whole row)
        const event = new Event('click');

        this.destroyShowingInputTargets.forEach(input => {
            input.checked = checked
            input.dispatchEvent(event)
        })
    }
}
